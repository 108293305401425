import styled from "styled-components";

const ButtonContainer = styled.div`
    display: flex;
    // margin: 10px 25px;
    flex-direction: row;
`;

const CustomButton = styled.button.attrs({
    className: "w-10rem scalein animation-duration-150",
})`
    background-color: var(--primary-ui);
    color: #fff;
    font-size: 1rem;
    font-weight: 900;
    border-radius: 5px;
    border: 0px;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const HeaderContainer = styled.div.attrs({
    className: "grid grid-nogutter justify-content-between",
})`
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;


const EventsLogContainer = styled.div.attrs({
    className: "sm:pt-2 lg:text-md xl:text-lg",
})`
    margin: 10px;
    width: 100%;
    // padding: 25px;
    box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
`;
const DataTableContainer = styled.div`
    // margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const PageHeader = styled.h2.attrs({
    className: "scalein animation-duration-150",
})`
    text-align: left;
    margin: 0px 20px;
    flex-grow: 1;
    color: var(--primary-ui);
`;
const TableContainer = styled.div`
    margin: 10px 20px;
    color: var(--primary-ui);
`;

const TemplateContainer = styled.div.attrs({className:"fadein animation-duration-150"})`
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const LStyles = {
    ButtonContainer,
    CustomButton,
    HeaderContainer,
    EventsLogContainer,
    DataTableContainer,
    PageHeader,
    TableContainer,
    TemplateContainer
}