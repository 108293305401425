import styled from "styled-components";

const PageHeader = styled.div.attrs({
    className: "scalein animation-duration-150 mt-2",
  })`
    text-align: left;
    // margin: 0px 20px;
    flex-grow: 1;
    color: var(--primary-ui);
    font-weight: bold;
  `;
  const PageHeader2 = styled.div.attrs({
    className: "scalein animation-duration-150 mt-4",
  })`
    text-align: left;
    margin: 10px 20px;
    flex-grow: 1;
    color: var(--primary-ui);
  `;
  const TitleContent = styled.div.attrs({
    className: "scalein animation-duration-150",
  })`
    text-align: left;
    margin: 5px 20px;
    color: var(--primary-ui);
  `;
  
  const CustomButton = styled.button.attrs({
    className: "scalein animation-duration-150",
  })`
    background-color: var(--primary-ui);
    color: #fff;
    font-size: 0.75rem;
    font-weight: 900;
    border-radius: 5px;
    border: 0px;
    height: 50px;
    // padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // width:12rem;
    @media (max-width: 768px) {
      // width: 10rem;
}
  `;
  
  const Container2 = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 30px;
    color: var(--primary-ui);

    @media (max-width: 768px) {
    display: flex;
      position: relative;
      justify-content: right;
    }

    @media (max-height:680px) {
    display: flex;
      position: relative;
      justify-content: right;
    }
    `
    
const ButtonContainer = styled.div.attrs({
  className: "justify-content-evenly",
})`
    display: flex;
    // margin: 15px 20px;
    flex-direction: row;

`;
const ImageContainer = styled.img.attrs({
  className: "col-12 m-0 h-8rem p-0 fadein animation-duration-150",
})``;

const PageContainer = styled.div.attrs({
  className: "col-12 md:col-6 lg:col-6 pb-0 pt-0",
})`
  color: var(--primary-ui);
`;



export const DStyles = {
    PageHeader,
    PageHeader2,
    TitleContent,
    CustomButton,
    Container2,
    ButtonContainer,
    ImageContainer,
    PageContainer
}