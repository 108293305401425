import React, {useState, useRef, MouseEventHandler, FunctionComponent} from "react";
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {EventPublishService} from "../../../service/eventPublishService";
import {Toast} from "primereact/toast";
import {vens, signalNames, EventPayload} from "./eventsFormData";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate} from "react-router-dom";
import {EStyles} from "./eventsformStyles";
import { showToast } from "../../../Utils/Utility";
import { RadioButton } from "primereact/radiobutton";
import { Payload, signalPayloadValues } from "../../../types/signalValues";

type ChildProps = {
    onPublishEvent: (data: string) => void;
    setVisible: (data: boolean) => void;
    setLoader: (data: boolean) => void;
};

export const EventsForm:FunctionComponent<ChildProps> = ({onPublishEvent, setVisible,setLoader}) => {

    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [formvalue, setFormvalue] = useState<{ [key: string]: any }>(EventPayload)
    const beginDatePickerRef = useRef<DatePicker>(null);
    const endDatePickerRef = useRef<DatePicker>(null);
    const [selectedBeginDate, setSelectedBeginDate] = useState<Date | null>(null);
    const [selectedBeginDateTemp, setSelectedBeginDateTemp] = useState<any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [selectedEndDateTemp, setSelectedEndDateTemp] = useState<Date | null>(null);
    const [selectedPayload, setSelectedPayload] = useState<Payload | null>(null);

    const handleBeginDateChange = () => {
        setSelectedBeginDate(selectedBeginDateTemp);
        let payload = {...formvalue};
        payload["beginDate"] = selectedBeginDateTemp
        setFormvalue(payload);
    }

    const handleEndDateChange = () => {
        setSelectedEndDate(selectedEndDateTemp);
        let payload = {...formvalue};
        payload["endDate"] = selectedEndDateTemp;
        setFormvalue(payload);
    }

    const handleFormChange: Function = (e: React.ChangeEvent<HTMLInputElement>) => {
        let payload = {...formvalue};
        payload[e.target.id] = e.target.value;
        setFormvalue(payload);
    }

    const handleBeginDateTextBoxClick = () => {

        if (beginDatePickerRef.current) {
            beginDatePickerRef.current.setOpen(true);
        }

    }

    const handleEndDateTextBoxClick = () => {
        if (endDatePickerRef.current) {
            endDatePickerRef.current.setOpen(true);
        }
    }

    const handleCheckBoxChange: Function = (id: string, e: boolean) => {
        let payload = {...formvalue};
        payload[id] = e ? true : false;
        setFormvalue(payload);
    }
    const handleRadioBoxChange = (value:any) => {
        let payload = { ...formvalue };
        payload["signalPayload"] = value.key;
        setFormvalue(payload);
        setSelectedPayload(value);
    };

    const sendEvent: MouseEventHandler<HTMLButtonElement> = async () => {
        try {
            if (validateEventPayload(formvalue)) {
                setLoader(true);
                let response = await EventPublishService(formvalue)
                if (response?.status === 200 || response?.status === 201) {
                    setVisible(false)
                    onPublishEvent(response?.data?.data?.request_id);
                } else if (response?.status === 403) {
                    navigate("/login");
                } else {
                    setLoader(false);
                    showToast(toast,"error", "Failure", "Server returned " + response?.status, false);                }
            }
        } catch (error) {
            setLoader(false);
            showToast(toast,"error", "Failure", "unable to publish event", false);
        }
    };    

    function validateEventPayload(formvalue: { [key: string]: any; }) {
        const isNull = Object.values(formvalue).some(value => {
            return value === null || value === "";
        });
        if (isNull) {
            showToast(toast, "error", "Payload Validation Error", "Please enter all the required input fields", false)
            return false
        } else {
            if (formvalue['beginDate'] > new Date() && formvalue['endDate'] > formvalue['beginDate']) {
                return true
            } else {
                showToast(toast, "error", "Payload Validation Error", "Please check the calender inputs", false)
                return false
            }

        }
    }

    function isFeatureDate(date: Date) {
        const today = new Date();
        const tempDate = new Date(date.getTime());
        today.setHours(0, 0, 0, 0); 
        tempDate.setHours(0, 0, 0, 0);
        return tempDate > today;
    }

    const clearForm = () => {
        setFormvalue({
            target: null,
            vtnName: 'FORD_VTN_SIMULATOR',
            beginDate: null,
            endDate: null,
            signalName: 'SIMPLE',
            area: '48216',
            signalPayload: null,
            testEvent: false,
            duration: 'PT60'
        });
        setSelectedBeginDate(null);
        setSelectedEndDate(null);
        setSelectedBeginDateTemp(null);
        setSelectedEndDateTemp(null);
        setSelectedPayload(null)
    };

    return (
    <EStyles.EventsFormContainer className="p-grid p-fluid">
        <Toast ref={toast}/>
       <EStyles.EventsInputContainer>
                <EStyles.RowContainer>
                <EStyles.LeftColumnContainer className="mt-4"><span>Target :</span></EStyles.LeftColumnContainer>
                <EStyles.RightColumnContainer>
                    <EStyles.CheckboxContainer>
                            <Checkbox data-testid="virtual-end-node" inputId="ven" name="ven" disabled
                                      onChange={(e) => handleCheckBoxChange("ven", e.checked)} checked={true}/>
                            <label htmlFor="ven">Virtual End Node</label>
                            </EStyles.CheckboxContainer>
                        <Dropdown data-testid="target" id="target"
                                  value={formvalue['target']}
                                  onChange={(e) => handleFormChange(e)}
                                  options={vens} optionLabel="name" optionValue="value"
                                  appendTo={"self"} placeholder="Select Target" className="w-full"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Event Start :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <InputText data-testid="begin-date" id="beginDate"
                                   value={selectedBeginDate ? selectedBeginDate.toLocaleDateString('en-US', {
                                       year: 'numeric',
                                       month: '2-digit',
                                       day: '2-digit',
                                       hour: '2-digit',
                                       minute: '2-digit',
                                       hour12: false
                                   }).replace(',', '') : ''} onClick={handleBeginDateTextBoxClick}
                        />


                        <EStyles.StyledDatePicker ref={beginDatePickerRef} selected={selectedBeginDateTemp}
                                          onChange={(date: Date) => {
                                              setSelectedBeginDateTemp(date)
                                          }}
                                          showTimeSelect
                                          onCalendarClose={() => handleBeginDateChange()}
                                          timeFormat="HH:mm"
                                          timeIntervals={30}
                                          timeCaption="time"
                                          minDate={new Date()}
                                          minTime={new Date(selectedBeginDateTemp && isFeatureDate(selectedBeginDateTemp) ? new Date().setHours(0, 0) : new Date())}
                                          maxTime={new Date(new Date().setHours(23, 59))}
                                          dateFormat="MM/dd/yyy HH:mm"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Event End :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <InputText data-testid="end-date" id="endDate"
                                   value={selectedEndDate ? selectedEndDate.toLocaleDateString('en-US', {
                                       year: 'numeric',
                                       month: '2-digit',
                                       day: '2-digit',
                                       hour: '2-digit',
                                       minute: '2-digit',
                                       hour12: false
                                   }).replace(',', '') : ''} onClick={handleEndDateTextBoxClick}
                        />

                        <EStyles.StyledDatePicker ref={endDatePickerRef} selected={selectedEndDateTemp}
                                          onChange={(date: Date) => {
                                              setSelectedEndDateTemp(date)
                                          }}
                                          onCalendarClose={() => handleEndDateChange()}
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeIntervals={30}
                                          timeCaption="time"
                                          minDate={new Date()}
                                          minTime={new Date(selectedEndDateTemp && isFeatureDate(selectedEndDateTemp) ? new Date().setHours(0, 0) : new Date())}
                                          maxTime={new Date(new Date().setHours(23, 59))}
                                          dateFormat="MM/dd/yyy HH:mm"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>

                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Area :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <InputText data-testid="area" id="area" value={formvalue['area']} disabled={true}
                                   onChange={e => handleFormChange(e)}/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer><span>Signal Name :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <Dropdown data-testid="signal-name" id="signalName" value={formvalue["signalName"]}
                                  onChange={(e) => handleFormChange(e)}
                                  options={signalNames} optionLabel="name" optionValue="value"
                                  placeholder="Select a signal name" className="w-full"/>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.LeftColumnContainer className="mb-6"><span>Signal Payload Value :</span></EStyles.LeftColumnContainer>
                    <EStyles.RightColumnContainer>
                        <EStyles.SignalPayloadContainer>
                            {signalPayloadValues.map((payloadValues) => {
                                return (
                                    <div key={payloadValues.key} className="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2">
                                        <div className="flex align-items-center">
                                        <RadioButton
                                            inputId={payloadValues.key.toString()}
                                            name="category"
                                            value={payloadValues}
                                            data-testid="signal-payload"
                                            onChange={(e) => handleRadioBoxChange(e.value)}
                                            checked={selectedPayload ? selectedPayload.key === payloadValues.key : undefined}
                                        />
                                        <label htmlFor={payloadValues.key.toString()} className="ml-2">{payloadValues.name}</label>
                                    </div>
                                    </div>
                                );
                            })}
                        </EStyles.SignalPayloadContainer>
                        <EStyles.CheckboxContainer>
                            <Checkbox data-testid="test-event" inputId="testEvent" name="testEvent"
                                className={"testEvent"}
                                onChange={(e) => handleCheckBoxChange("testEvent", e.target.checked)}
                                checked={formvalue['testEvent'] === true} />
                            <label htmlFor="testEvent">Is a Test Event?</label>
                        </EStyles.CheckboxContainer>
                    </EStyles.RightColumnContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.EventsContainerButton onClick={clearForm}>Clear</EStyles.EventsContainerButton>
                    <EStyles.EventsContainerButton id={"publish"} onClick={sendEvent}>Publish</EStyles.EventsContainerButton>
                </EStyles.RowContainer>
            </EStyles.EventsInputContainer>
    </EStyles.EventsFormContainer>)    
}


