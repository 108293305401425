import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React ,{ useRef, useState } from "react";
import { TbCopy, TbInfoCircle, TbTrash } from "react-icons/tb";
import { EOverlayStyles } from "../../events/EventsOverlay/EventsOverlayStyles";

export const ScheduleRequestIdTemplate = ({ rowData, onConfirm }: { rowData: any, onConfirm: (rowData: any) => void }) => {    
    const toast = useRef<Toast>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const op = useRef<any>(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(rowData.request_id)
            .then(() => {
                toast.current?.show({ severity: "success", detail: 'Id copied to clipboard' });
            })
            .catch(err => {
                toast.current?.show({ severity: "info", summary: 'Error', detail: `Failed to copy tooltip content : ${err}` });
            });
    };

    return (
        <div className="card flex justify-content-center">
            <Button
                text
                icon={<TbInfoCircle />}
                style={{ height: "0.25rem" }}
                onClick={(e) => op.current.toggle(e)}
            />
            <OverlayPanel ref={op} className='text-sm fadein animation-duration-100' closeOnEscape dismissable pt={{ content: { className: 'p-2' } }}>
                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Request ID</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.request_id}</span>
                                <button className="ml-2" onClick={handleCopy} style={{ background: "none", border: "none", cursor: "pointer" }}>
                                    <TbCopy />
                                </button>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Target</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.payload?.ven_id}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Test Event</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData.payload?.test_event ? "Yes" : "No"}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Response Required</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData.payload?.response_required ? "Yes" : "No"}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Time Zone</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData.payload?.time_zone}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Created By</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData.payload?.created_by}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                    </tbody>
                </table>
            </OverlayPanel>
            <Button
                disabled={rowData.status !== "PUBLISHED" && rowData.status !== "CANCELLED" && rowData.status !== "FAILED" ? false : true}
                text
                icon={<TbTrash />}
                style={{ height: "0.25rem" }}
                onClick={() => onConfirm(rowData)}                
                className="p-button-danger"
                id={`delete-${rowData.id}`}
            />
        </div>
    );
}