import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from "react";
import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ClipLoader } from "react-spinners";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";
import { EventDto } from "../../../types/event";
import { EventDeliveryDto } from "../../../types/event.delivery";
import { LStyles } from "./eventlogsStyles";
import { showToast } from "../../../Utils/Utility";
import ResponseObjectTemplate from "../EventsOverlay/events_overlay";
import { EventLogService } from "../../../service/eventLogService";
import { EventDeliveryService } from "../../../service/eventDeliveryService";
import { EventsForm } from "../EventsForm/events_form";
import { ScrollPanel } from "primereact/scrollpanel";
import collapseIcon from '../../../assets/images/collapseIcon.svg'
import generateIcon2 from '../../../assets/images/generateIcon2.svg'
import moment from "moment";
import { EventRequestIdTemplate } from "../EventsIdTemplate/events_id_template";

export const EventsLog: FunctionComponent = () => {
    const navigate = useNavigate();
    const [eventLogs, setEventLogs] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [loadEvents, setLoadEvents] = useState(true);
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>({});
    const [scrollHeight, setScrollHeight] = useState(window.innerHeight + 'px');
    const [scrollWidth, setScrollWidth] = useState(window.innerWidth + 'px');
    const [rowData, setRowData] = useState<any>({});
    const toast = useRef<Toast>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableSize, setTableSize] = useState<any>('normal')
    const [publishedEventId, setPublishedEventId] = useState<string>()
    const [paginatorTemplate, setPaginatorTemplate] = useState('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown');
    const [dataTableClass, setDataTableClass] = useState('');
    const responseAcknowledgedTemplate = (rowData: EventDeliveryDto) => {
        return rowData?.response_code === 200 || rowData?.response_code === 201 ? 'Success' : 'Failed';
    }

    const responseCodeTemplate = (rowData: EventDeliveryDto) => {
        return rowData?.response_code ? rowData?.response_code : 'NULL';
    }

    const responseCreatedDateTemplate = (rowData: EventDto) => {
        return rowData?.created_at ? moment(rowData?.created_at).format('MM/DD/YYYY, HH:mm') : 'NULL'
    }

    const responseStartDateTemplate = (rowData: EventDto) => {
        return rowData?.start_time ? moment(rowData?.start_time).format('MM/DD/YYYY, HH:mm') : 'NULL'
    }

    const responseEndDateTemplate = (rowData: EventDto) => {
        return rowData?.end_time ? moment(rowData?.end_time).format('MM/DD/YYYY, HH:mm') : 'NULL'
    }

    const onRowCollapse = () => {
        setExpandedRows(null);
    };

    const expandTemplate = (data: EventDto) => {
        const isLoading: any = loadingRows[data.id];
        return (
            <LStyles.TemplateContainer>
                <DataTable value={data?.delivery_logs} stripedRows style={{ border: "none" }} emptyMessage="No records found." loading={isLoading} size={tableSize} className="sm:text-xs md:text-xs lg:text-sm xl:text-md">
                    <Column field="consumer_name" header="Name" sortable style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} />
                    <Column field="response_acknowledged" header="Acknowledgement" sortable
                        style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} body={responseAcknowledgedTemplate} />
                    <Column field="response_code" header="Response Code" sortable style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} body={responseCodeTemplate} />
                    <Column field="response_object" header="Payload Sent" body={ResponseObjectTemplate} style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} />
                </DataTable>
            </LStyles.TemplateContainer>
        );
    };

    const onRowToggle = async (event: { data: any }) => {
        const expanded = event?.data;
        setExpandedRows(expanded);

        if (expanded?.length > 0) {
            const expandedRowId = expanded[expanded?.length - 1]?.id;
            if (!rowData[expandedRowId]) {
                try {
                    const apiData = await fetchDeliveryLog(expandedRowId);
                    setRowData((prevData: object) => ({ ...prevData, [expandedRowId]: apiData }));
                } catch (error) {
                    console.error("Error fetching delivery logs:", error);
                }
            }
        }
    };

    const updateWidth = () => {
        if (window.innerWidth < 769) {
            setScrollWidth(window.innerWidth - 70 + 'px');
        } else {
            setScrollWidth(window.innerWidth - 160 + 'px');
        }
    };

    const updateHeight = () => {
        setScrollHeight(window.innerHeight < 600 ? window.innerHeight - 150 + 'px': window.innerHeight - 300 + 'px');
    };

    const fetchEvents = useCallback(async () => {
        setIsLoading(true)
        let response = await EventLogService()
        try {
            if (response?.status === 200) {
                setIsLoading(false)
                setEventLogs(response?.data?.data.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))
            } else if (response.status === HttpStatusCode.Forbidden) {
                navigate("/login");
                setIsLoading(false)
                console.log("Error fetching events " + response.data.message);
            }
        } catch (error) {
            setIsLoading(false)
            console.log("Error fetching events ", error);
            showToast(toast, "error", "Oops!", "Error Fetching Events", false);
        }
    }, [navigate]);

    useEffect(() => {
        if (loadEvents) {
            fetchEvents();
            setLoadEvents(false);
        }
        if (publishedEventId) {
            showToast(toast, "success", "Event Published!", `Id : ${publishedEventId}`, true);
        }
    }, [loadEvents, fetchEvents, isLoading, publishedEventId]);
    
    useEffect(() => {
        updateWidth()
        updateHeight()
        const handleResize = () => {
            updateWidth()
            updateHeight()
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    })

    useEffect(() => {
        const updateSize = () => {
           if (window.innerWidth < 1599) {
                setTableSize('small');
            } else if (window.innerWidth > 1600) {
                setTableSize('normal');
            } else {
                setTableSize('normal');
            }
        };
        const updateHeightTable = ()=> {
            if (window.outerHeight < 600) {
                setDataTableClass('datatable-small');
                setTableSize('small');
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        window.addEventListener('resize', updateHeightTable);
        updateHeightTable();

        return () => window.removeEventListener('resize', updateSize);
    }, [dataTableClass,tableSize]);

    useEffect(() => {
        const updatePaginatorTemplate = () => {
            if (window.innerWidth >= 1024) {
                setPaginatorTemplate('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown');
                setDataTableClass('');
            } else {
                setPaginatorTemplate('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown');
                setDataTableClass('datatable-small');
            }
        };

        updatePaginatorTemplate();
        window.addEventListener('resize', updatePaginatorTemplate);

        return () => {
            window.removeEventListener('resize', updatePaginatorTemplate);
        };
    }, []);

    const fetchDeliveryLog = async (eventDto: string) => {
        setLoadingRows(prevState => ({ ...prevState, [eventDto]: true }));

        try {
            const response = await EventDeliveryService(eventDto);
            if (response?.status === 200) {
                eventLogs.forEach((item) => {
                    if (item.id === eventDto) {
                        item.delivery_logs = response?.data?.data;
                    }
                });
            } else if (response.status === HttpStatusCode.Forbidden) {
                navigate("/login");
                showToast(toast, "error", "Oops!", "Error Fetching Delivery Logs", false);
            } else {
                console.log("Error fetching event delivery data" + response.data.message);
            }
        } catch (error) {
            showToast(toast, "error", "Oops!", "Error Fetching Delivery Logs", false);
        } finally {
            setLoadingRows(prevState => ({ ...prevState, [eventDto]: false }));
        }
    };

    const hideDialog = () => {
        setVisible(false);
    };

    const handleChildEvent = (requestId: string) => {
        setLoadEvents(true);
        setPublishedEventId(requestId)
    };
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);

    const onPageChange = (event: DataTablePageEvent) => {
        setLoading(true);
        setTimeout(() => {
            setFirst(event.first);
            setRows(event.rows);
            setLoading(false);
        }, 500);
    };

    return (
        <>
            {isLoading ?
                <div className="loader">
                    <ClipLoader color="var(--primary-ui)" loading={isLoading} size={100} aria-label="Publishing" data-testid="loader" className="loader-img" />
                </div>
                :
                <LStyles.EventsLogContainer>
                    <LStyles.HeaderContainer>
                        <Toast ref={toast} position="top-right" />
                        <LStyles.PageHeader>PUBLISHED EVENTS</LStyles.PageHeader>
                        <LStyles.ButtonContainer className="m-2">
                            <LStyles.CustomButton onClick={() => onRowCollapse()}><img src={collapseIcon} alt="generateIcom" className="mr-2" />Collapse All</LStyles.CustomButton> &nbsp;
                            <LStyles.CustomButton onClick={() => setVisible(true)} data-testid="generate-btn"><img src={generateIcon2} alt="generateIcom" className="mr-2" />
                                Generate Event</LStyles.CustomButton>

                        </LStyles.ButtonContainer>
                    </LStyles.HeaderContainer>
                    <LStyles.DataTableContainer>
                        <LStyles.TableContainer>
                            <ScrollPanel style={{ width: scrollWidth }}>
                                <DataTable paginatorTemplate={paginatorTemplate} value={eventLogs} size={tableSize} stripedRows scrollable scrollHeight={scrollHeight}
                                    onPage={onPageChange} loading={loading}
                                    paginator rows={rows} first={first} onRowCollapse={onRowCollapse}
                                    filterDisplay={"menu"}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    expandedRows={expandedRows} onRowToggle={onRowToggle} rowExpansionTemplate={expandTemplate} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events" emptyMessage="No records found." className={`${dataTableClass} sm:text-xs md:text-xs lg:text-sm xl:text-md fadein animation-duration-100`}>
                                    <Column expander headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="created_at" sortable field="created_at" header="Created At"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseCreatedDateTemplate} />
                                    <Column key="signal_name" sortable field="signal_name" header="Signal Name"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="ven_id" sortable field="ven_id" header="Target"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="start_time" sortable field="start_time" header="Event Start"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseStartDateTemplate} />
                                    <Column key="end_time" sortable field="end_time" header="Event End"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseEndDateTemplate} />
                                    <Column key="signal_payload" sortable field="signal_payload" header="Signal Payload"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="area_code" sortable field="area_code" header="Area"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="request_id" field="request_id"
                                        headerStyle={{ backgroundColor: "lightgray" }}
                                        body={(rowData) => (
                                            <EventRequestIdTemplate rowData={rowData} />
                                          )}/>
                                </DataTable>
                            </ScrollPanel>
                        </LStyles.TableContainer>
                    </LStyles.DataTableContainer>
                    <Dialog
                        visible={visible}
                        style={{ width: '50vw' }}
                        className={"p-fluid"}
                        header="Generate Event"
                        modal
                        onHide={hideDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask
                        pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                        <EventsForm
                            onPublishEvent={handleChildEvent}
                            setVisible={setVisible}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                </LStyles.EventsLogContainer>}
        </>)
}