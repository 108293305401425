import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EStyles } from '../events/EventsForm/eventsformStyles';
import { LStyles } from '../events/EventsLog/eventlogsStyles';

const StartPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = (path: string) => {
        navigate(path);
    };

    const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  height:75%;
`;

    return (
        <Container>
            <div className='text-4xl'>Grid Connect Dashboard</div>
            <LStyles.CustomButton onClick={() => handleButtonClick('/events')} className="m-2 w-13rem" >Demand Response</LStyles.CustomButton>
            <LStyles.CustomButton className="m-2 w-13rem" >Upload Variable Tariff</LStyles.CustomButton>
            <LStyles.CustomButton className="m-2 w-13rem" >View MnV Data</LStyles.CustomButton>
            <LStyles.CustomButton className="m-2 w-13rem" >View Customer Data</LStyles.CustomButton>
        </Container>
    );
};

export default StartPage;