import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React ,{ useRef } from "react";
import { TbCopy, TbInfoCircle } from "react-icons/tb";
import { EOverlayStyles } from "../EventsOverlay/EventsOverlayStyles";
import moment from "moment";

export const EventRequestIdTemplate = ({ rowData }: any) => {
    const toast = useRef<Toast>(null);

    const op = useRef<any>(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(rowData.request_id)
            .then(() => {
                toast.current?.show({ severity: "success", detail: 'Id copied to clipboard' });
            })
            .catch(err => {
                toast.current?.show({ severity: "info", summary: 'Error', detail: `Failed to copy tooltip content : ${err}` });
            });
    };

    return (
        <div className="card flex justify-content-center">
            <Button
                rounded
                text
                icon={<TbInfoCircle />}
                style={{ height: "0.25rem" }}
                onClick={(e) => op.current.toggle(e)}
            />
            <OverlayPanel ref={op} className='text-sm fadein animation-duration-100' closeOnEscape dismissable pt={{ content: { className: 'p-2' } }}>
                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Request ID</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.request_id}</span>
                                <button className="ml-2" onClick={handleCopy} style={{ background: "none", border: "none", cursor: "pointer" }}>
                                    <TbCopy />
                                </button>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>VTN Name</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.vtn_name}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Duration</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.duration}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Test Event</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.payload?.test_event ? "Yes" : "No"}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Response Required</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.payload?.response_required ? "Yes" : "No"}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Time Zone</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.time_zone}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Created By</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.created_by}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>Created At</EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{moment(rowData?.created_at).format('MM/DD/YYYY, HH:mm')}</span>
                            </EOverlayStyles.CellStyle>
                        </tr>
                    </tbody>
                </table>
            </OverlayPanel>
        </div>
    );
}