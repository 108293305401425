import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from "react";
import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ClipLoader } from "react-spinners";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../Utils/Utility";
import { ScrollPanel } from "primereact/scrollpanel";
import { TbEdit } from "react-icons/tb";
import { CgCloseO } from "react-icons/cg";
import { LStyles } from "../../events/EventsLog/eventlogsStyles";
import { SchedulesForm } from "../SchedulesForm/schedulesForm";
import { ScheduleLogService } from "../../../service/scheduleLogService";
import { SchedulesEditForm } from "../SchedulesEditForm/schedulesEditForm";
import scheduleIcon from '../../../assets/images/scheduleIcon.svg'
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { DeleteScheduleService } from "../../../service/eventScheduleService";
import moment from "moment";
import { ScheduleRequestIdTemplate } from "../SchedulesIdTemplate/schedules_id_template";

const SchedulesLog: FunctionComponent = () => {
    const navigate = useNavigate();
    const [eventLogs, setEventLogs] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [loadSchedules, setLoadSchedules] = useState(true);
    const [scrollHeight, setScrollHeight] = useState(window.innerHeight + 'px');
    const [scrollWidth, setScrollWidth] = useState(window.innerWidth + 'px');
    const toast = useRef<Toast>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableSize, setTableSize] = useState<any>('normal')
    const [publishedEventId, setPublishedEventId] = useState<string>()
    const [paginatorTemplate, setPaginatorTemplate] = useState('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown');
    const [dataTableClass, setDataTableClass] = useState('');
    
    const responseScheduleDateTemplate = (rowData: any) => {
        return (
            <div>
                {rowData?.schedule ? moment(rowData?.schedule).format('MM/DD/YYYY, HH:mm') : 'NULL'}
                {
                    rowData.status !== "PUBLISHED" && rowData.status !== "CANCELLED" && rowData.status !== "FAILED" ?
                        <button style={{ background: "none", border: "none", fontSize: "1rem", cursor: "pointer" }} onClick={() => { setVisibleEdit(true); setSelectedRowData(rowData); }} data-testid="generate-btn"><TbEdit /></button>
                        : ''}
            </div>
        )
    }

    const responseStartDateTemplate = (rowData: any) => {
        return rowData?.payload?.start_time ? moment(rowData?.payload?.start_time).format('MM/DD/YYYY, HH:mm') : 'NULL'
    }

    const responseEndDateTemplate = (rowData: any) => {
        return rowData?.payload?.end_time ? moment(rowData?.payload?.end_time).format('MM/DD/YYYY, HH:mm') : 'NULL'
    }

    const updateWidth = () => {
        if (window.innerWidth < 769) {
            setScrollWidth(window.innerWidth - 70 + 'px');
        } else {
            setScrollWidth(window.innerWidth - 160 + 'px');
        }
    };

    const updateHeight = () => {
        setScrollHeight(window.innerHeight < 600 ? window.innerHeight - 150 + 'px' : window.innerHeight - 200 + 'px');
    };

    const fetchSchedules = useCallback(async () => {
        setIsLoading(true)
        let response = await ScheduleLogService()
        try {
            if (response?.status === 200) {
                setIsLoading(false)
                // let indexedEvents = response?.data?.data.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                setEventLogs(response?.data?.data);
            } else if (response.status === HttpStatusCode.Forbidden) {
                navigate("/login");
                setIsLoading(false)
                console.log("Error fetching Scheduled Events " + response.data.message);
            }
        } catch (error) {
            setIsLoading(false)
            console.log("Error fetching Scheduled Events ", error);
            showToast(toast, "error", "Oops!", "Error Fetching Scheduled Events", false);
        }
    }, [navigate]);

    useEffect(() => {
        if (loadSchedules) {
            fetchSchedules();
            setLoadSchedules(false);
        }
        console.log(publishedEventId);
        
        if(publishedEventId?.includes('Scheduled')) {
            showToast(toast,"success", "Event Scheduled!", `Id : ${publishedEventId.replace('Scheduled','')}`, true);
        } else if(publishedEventId?.includes('Updated')) {
            showToast(toast,"success", "Event Updated!", `Id : ${publishedEventId.replace('Updated','')}`, true);
        } else if(publishedEventId?.includes('Cancelled')) {
            showToast(toast,"success", "Event Cancelled!", `Id : ${publishedEventId.replace('Cancelled','')}`, true);
        }
       
    
    }, [loadSchedules, fetchSchedules, isLoading, publishedEventId]);

    useEffect(() => {
        updateWidth()
        updateHeight()
        const handleResize = () => {
            updateWidth()
            updateHeight()
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    })

    useEffect(() => {
        const updateSize = () => {
            if (window.innerWidth < 1599) {
                setTableSize('small');
            } else if (window.innerWidth > 1600) {
                setTableSize('normal');
            } else {
                setTableSize('normal');
            }
        };

        const updateHeightTable = ()=> {
            if (window.outerHeight < 600) {
                setDataTableClass('datatable-small');
                setTableSize('small');
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        window.addEventListener('resize', updateHeightTable);
        updateHeightTable();

        return () => window.removeEventListener('resize', updateSize);
    }, [dataTableClass,tableSize]);

    useEffect(() => {
        const updatePaginatorTemplate = () => {
            if (window.innerWidth >= 1024) {
                setPaginatorTemplate('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown');
                setDataTableClass('');
            } else {
                setPaginatorTemplate('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown');
                setDataTableClass('datatable-small');
            }
        };

        updatePaginatorTemplate();
        window.addEventListener('resize', updatePaginatorTemplate);

        return () => {
            window.removeEventListener('resize', updatePaginatorTemplate);
        };
    }, []);

    const hideDialog = () => {
        setVisible(false);
    };
    const hideEditDialog = () => {
        setVisibleEdit(false);
    };

    const handleChildEvent = (type:string,requestId: string) => {
        setLoadSchedules(true);
        setPublishedEventId(type + requestId)
    };
    
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);

    const onPageChange = (event: DataTablePageEvent) => {
        setLoading(true);
        setTimeout(() => {
            setFirst(event.first);
            setRows(event.rows);
            setLoading(false);
        }, 500);
    };

    const accept = async (rowData:any) => {
        setIsLoading(true)
        await DeleteScheduleService(rowData.id)
            .then(() => {
                handleChildEvent("Cancelled",rowData.payload?.request_id)
            })
            .catch((error) => {
                setIsLoading(false);
                showToast(toast, "error", "Schedule Cancellation Failed " + error, "", false);
            })
    }; 
    
    const confirm2 = (rowData:any) => {
        const targetElement = document.querySelector(`#delete-${rowData.id}`);

        if (targetElement instanceof HTMLElement) {
            confirmPopup({
                target: targetElement,
                message: 'Do you want to cancel this schedule?',
                icon: <CgCloseO />,
                defaultFocus: 'reject',
                accept: () => accept(rowData),
                acceptClassName: 'p-button-danger',
                acceptLabel: "Yes",
            });
        } else {
            console.error('Target element not found or is not an HTMLElement');
        }
    };

    return (
        <>
            {/* <EventsContainer> */}
            {isLoading ?
                <div className="loader">
                    <ClipLoader color="var(--primary-ui)" loading={isLoading} size={100} aria-label="Publishing" data-testid="loader" className="loader-img" />
                </div>
                :
                <LStyles.EventsLogContainer>
                    <LStyles.HeaderContainer>
                        <Toast ref={toast} position="top-right" />
                        <ConfirmPopup />
                        <LStyles.PageHeader>SCHEDULES</LStyles.PageHeader>
                        <LStyles.ButtonContainer className="m-2">
                            <LStyles.CustomButton onClick={() => setVisible(true)} data-testid="generate-btn"><img src={scheduleIcon} alt="generateIcon" className="mr-2" />Schedule Event</LStyles.CustomButton>
                        </LStyles.ButtonContainer>
                    </LStyles.HeaderContainer>
                    <LStyles.DataTableContainer>
                        <LStyles.TableContainer>
                            <ScrollPanel style={{ width: scrollWidth }}>
                                <DataTable paginatorTemplate={paginatorTemplate} value={eventLogs} size={tableSize} stripedRows scrollable scrollHeight={scrollHeight}
                                    onPage={onPageChange} loading={loading}
                                    paginator rows={rows} first={first}
                                    filterDisplay={"menu"} sortField="rowId" sortOrder={-1}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events" emptyMessage="No records found." className={`${dataTableClass} sm:text-xs md:text-xs lg:text-sm xl:text-md fadein animation-duration-100`}>
                                    <Column key="payload.signal_name" sortable field="payload.signal_name" header="Signal Name"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="schedule" sortable field="schedule" header="Scheduled Date & Time"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseScheduleDateTemplate} />
                                    <Column key="start_time" sortable field="payload?.start_time" header="Event Start"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseStartDateTemplate} />
                                    <Column key="payload.end_time" sortable field="payload.end_time" header="Event End"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseEndDateTemplate} />
                                    <Column key="payload.signal_payload" sortable field="payload.signal_payload" header="Signal Payload"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="payload.area_code" sortable field="payload.area_code" header="Area"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="status" sortable field="status" header="Status"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="request_id" field="payload.request_id"
                                        headerStyle={{ backgroundColor: "lightgray" }}
                                        body={(rowData) => (
                                            <ScheduleRequestIdTemplate rowData={rowData} onConfirm={confirm2} />
                                          )}/>
                                </DataTable>
                            </ScrollPanel>
                        </LStyles.TableContainer>
                    </LStyles.DataTableContainer>
                    <Dialog
                        visible={visible}
                        style={{ width: '50vw' }}
                        className={"p-fluid"}
                        header="Schedule Event"
                        modal
                        onHide={hideDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                        <SchedulesForm
                            onPublishEvent={handleChildEvent}
                            setVisible={setVisible}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                    <Dialog
                        visible={visibleEdit}
                        style={{ width: '50vw' }}
                        className={"p-fluid"}
                        header="Edit Schedule"
                        modal
                        onHide={hideEditDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                        <SchedulesEditForm
                            onPublishEvent={handleChildEvent}
                            rowData={selectedRowData}
                            setVisible={setVisibleEdit}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                </LStyles.EventsLogContainer>}
            {/* </EventsContainer> */}
        </>)
}

export default SchedulesLog