import axios from 'axios';

const BFF_URL = window.__RUNTIME_CONFIG__?.REACT_APP_BFF_URL || 'http://localhost:8080';

const axiosInstance = axios.create({
    baseURL: `${BFF_URL}/grid-connect/v1`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

export const setupAxiosInterceptors = (toast:any) => {    
    axiosInstance.interceptors.response.use(
        (response:any) => {
            const statusCode = response.status;
            if (statusCode === 401 || statusCode === 403) {
                window.location.replace('/login');
            } else if (statusCode === 200 || statusCode === 201) {
                return response;
            } else if (statusCode === 400) {
                return response;
            } else {
                window.location.replace('/error');
            }
            return new Promise(() => (''));
        },
        error => {
            const statusCode = error.response?.status;
            const apiName = error.config.apiName + " API" || 'Unknown API';
            console.log(`Network Error on : ${apiName} - ${statusCode}`);
            if (statusCode === 401 || statusCode === 403) {
                window.location.replace('/login');
            } else if (statusCode === 400) {                
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `${error?.response?.data.error?.error ? error?.response?.data?.error?.error : error.response?.data?.error?.message}`, life: 3000 });
                return error;
            } else {
                window.location.replace('/error');
            }
            return new Promise(() => (''));
        }
    );
};

export default axiosInstance;
