import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'primereact/resources/themes/md-light-indigo/theme.css'
import 'primeflex/primeflex.css'
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Auth from "./components/auth/auth";
import { EventsLog } from './components/events/EventsLog/events_log';
import Dashboard from './components/info/info';
import SchedulesLog from './components/schedules/SchedulesLog/schedulesLog';
import StartPage from './components/startpage/start_page';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App/>}>
                <Route path="/login" element={<Auth/>}></Route>
                <Route path= "/events" element={<EventsLog/>}></Route>
                <Route path= "/schedules" element={<SchedulesLog/>}></Route>
                <Route path= "/info" element={<Dashboard/>}></Route>
                <Route path= "/" element={<StartPage/>}></Route>
            </Route>
        </Routes>
    </BrowserRouter>
);
